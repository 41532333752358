import React, { useState } from "react"
import shortid from "shortid"
import Auth from "@aws-amplify/auth"
import Modal from "react-modal"
import PhoneInput from "react-phone-input-2"
import { navigate } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { useForm, Controller } from "react-hook-form"
import { useFetch } from "../../hooks/useFetch"
import config from "../../../config/website"
import taraNotebookImage from "../../assets/images/author/tara-notebook.png"
import backgroundPattern1Image from "../../assets/images/background/bg-pattern-1.png"

Modal.setAppElement(`#___gatsby`)

function ChallengeHero({ email = "" }) {
  const { t } = useTranslation()
  const [modalOpen, setModalOpen] = useState(false)
  const [data, setData] = useState({})
  const {
    handleSubmit,
    setError,
    formState,
    errors,
    control,
    register,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: { email },
    shouldFocusError: true,
  })
  const { pending, refetch } = useFetch({
    url: config.challengeSignUpApiUrl,
  })

  return (
    <section
      id="challenge-hero-section"
      className="main-section"
      style={{ backgroundImage: `url(${backgroundPattern1Image})` }}
    >
      <Modal
        isOpen={modalOpen}
        onRequestClose={() => setModalOpen(false)}
        contentLabel="Modal"
        style={{
          content: {
            maxWidth: 600,
            maxHeight: 380,
            backgroundColor: "rgb(249, 249, 249)",
            margin: "0 auto",
          },
        }}
      >
        <div className="awc-challenge">
          <h2 style={{ lineHeight: "1.2em" }}>
            Looks like you already have an Way account!
          </h2>
          <br />
          <p style={{ fontSize: 28, lineHeight: "1.4em" }}>
            Would you like to join the challenge anyway?
          </p>

          <button
            disabled={pending}
            className="cta w-full"
            onClick={() => {
              refetch({ email: data.email, phone: data.phone }).then(() =>
                navigate("/challenge_success")
              )
            }}
          >
            Yes, I'm In!
          </button>
          <div style={{ marginTop: 20, textAlign: "center" }}>
            <button style={{ padding: 10 }} onClick={() => setModalOpen(false)}>
              No, thank you
            </button>
          </div>
        </div>
      </Modal>

      <div className="container">
        <div className="header">
          <div className="free">• FREE ONLINE CHALLENGE •</div>

          <h1>
            BUILD YOUR OWN WEBSITE WITHOUT ANY TECH PEOPLE 🙅‍♀️ OR CODE IN{" "}
            <b>JUST 3 DAYS!</b>
          </h1>
        </div>

        <div className="row">
          <div className="col-md-6">
            <h2 className="headline">NO PRIOR EXPERIENCE REQUIRED</h2>

            <div className="divider" />

            <div className="form" id="form" name="form">
              <p className="subheadline">
                In just 5 days, I'll show you how to{" "}
                <b>build a beautiful website</b> <u>WITHOUT</u> any code!
              </p>

              <p className="subheadline">Create Your Account Now</p>

              <form
                onSubmit={handleSubmit(async data => {
                  setData(data)
                  try {
                    await Auth.signUp({
                      username: shortid.generate(),
                      password: data.password,
                      attributes: {
                        email: data.email,
                        name: data.email,
                      },
                    })
                    await refetch({ email: data.email, phone: data.phone })
                    await Auth.signIn(data.email, data.password)
                    await Auth.verifyCurrentUserAttribute("email")
                    navigate("/challenge_success")
                  } catch (e) {
                    if (e.code === "UserLambdaValidationException") {
                      setModalOpen(true)
                      setError("email", {
                        type: "server",
                        message: t("This user already exists"),
                      })
                    } else {
                      setError("phone", {
                        type: "server",
                        message: t("Check if your phone number is correct"),
                      })
                    }
                  }
                })}
              >
                <div className="form-group">
                  <input
                    className="form-control"
                    type="email"
                    name="email"
                    autoComplete="email"
                    placeholder={t("Email")}
                    ref={register({
                      required: t("Required"),
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: t("Invalid email address"),
                      },
                    })}
                  />
                  {errors.email && (
                    <small className="form-text text-danger">
                      {errors.email.message}
                    </small>
                  )}
                </div>

                <div className="form-group">
                  <Controller
                    as={
                      <PhoneInput
                        country="us"
                        countryCodeEditable={false}
                        inputProps={{
                          name: "phone",
                          required: true,
                        }}
                        name="phone"
                        enableSearch
                      />
                    }
                    name="phone"
                    control={control}
                    defaultValue=""
                  />
                  {errors.phone && (
                    <small className="form-text text-danger">
                      {errors.phone.message}
                    </small>
                  )}
                </div>

                <div className="form-group">
                  <input
                    className="form-control"
                    type="password"
                    name="password"
                    autoComplete="new-password"
                    placeholder={t("Password")}
                    ref={register({
                      required: t("Required"),
                      pattern: {
                        value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/,
                        message: t(
                          "Your password must be 6-20 characters long and contain a minimum of 1 lower case letter, 1 upper case letter and 1 number. It must not contain spaces, fancy special characters, or emoji."
                        ),
                      },
                    })}
                  />
                  {errors.password && (
                    <small className="form-text text-danger">
                      {errors.password.message}
                    </small>
                  )}
                </div>

                <button
                  disabled={formState.isSubmitting}
                  className="cta w-full"
                >
                  Join the Challenge Now
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-6">
            <img src={taraNotebookImage} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default ChallengeHero
