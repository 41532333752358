import React from "react"
import { Router } from "@reach/router"
import SEO from "../components/seo"
import ChallengeHero from "../components/challenge/hero"
import ChallengeQuote from "../components/challenge/quote"
import ChallengeBenefits from "../components/challenge/benefits"
import ChallengeAuthor from "../components/challenge/author"
import ChallengeFooter from "../components/challenge/footer"

const Challenge = ({ email }) => (
  <div className="awc-challenge">
    <SEO title="Build your own Website challenge" />
    <ChallengeHero email={email} />
    <ChallengeQuote />
    <ChallengeBenefits />
    <ChallengeAuthor />
    <ChallengeFooter />
  </div>
)

const ChallengePage = () => (
  <Router>
    <Challenge path="/challenge" />
    <Challenge path="/challenge/:email" />
  </Router>
)

export default ChallengePage
