import React from "react"

function ChallengeFooter() {
  return (
    <section className="challenge-footer-section">
      <div className="container">
        <div className="divider" />

        <div className="info">
          <a
            href="https://www.appswithoutcode.com/terms-and-conditions"
            target="_parent"
            rel="noopener noreferrer"
          >
            <b>TERMS &amp; CONDITIONS</b>
          </a>
          &nbsp; &nbsp;|&nbsp; &nbsp;
          <a
            href="https://www.appswithoutcode.com/privacy-policy"
            target="_parent"
            rel="noopener noreferrer"
          >
            <b>PRIVACY POLICY</b>
          </a>
          <div>
            <b>
              CONTACT US AT{" "}
              <a href="mailto:support@appswithoutcode.com">
                support@appswithoutcode.com
              </a>
            </b>
          </div>
        </div>

        <div>All Rights Reserved 2021 © Copyright Apps Without Code</div>
      </div>
    </section>
  )
}

export default ChallengeFooter
