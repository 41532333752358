import React from "react"

function ChallengeQuote() {
  return (
    <section className="quote-section">
      <div className="container">
        <h2>
          YOU DON'T NEED A DEGREE IN IT OR A JOB IN SILICON VALLEY
          <br />
          TO TAKE THIS 3 DAY WEBSITE BUILDING CHALLENGE
        </h2>
      </div>
    </section>
  )
}

export default ChallengeQuote
