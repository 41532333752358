import React from "react"
import { Link } from "gatsby"
import taraProfileImage from "../../assets/images/author/tara-profile.png"

function ChallengeAuthor() {
  return (
    <section className="author-section">
      <div className="container">
        <div className="header">
          <div className="free">• FREE ONLINE CHALLENGE •</div>
          <h2 className="headline">MEET YOUR HOST TARA REED</h2>
        </div>

        <div className="content">
          <div className="row">
            <div className="col-md-4">
              <img src={taraProfileImage} />
            </div>

            <div className="col-md-8">
              <div className="info">
                <div className="subheadline">FOUNDER, APPS WITHOUT CODE</div>
                <div className="subheadline">FORBES 30 UNDER 30</div>

                <p>
                  Apps Without Code helps first-time entrepreneurs develop apps
                  without the hurdle of knowing how to write code, and gives
                  them fundamental tools and strategies to launch successful,
                  profitable app businesses.
                </p>

                <Link to="#form" className="cta w-full">
                  Yes! I'm in
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ChallengeAuthor
