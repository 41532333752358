import React from "react"
import { Link } from "gatsby"
import benefit1Image from "../../assets/images/benefits/benefit-1.png"
import benefit2Image from "../../assets/images/benefits/benefit-2.png"
import benefit3Image from "../../assets/images/benefits/benefit-3.png"

function ChallengeBenefits() {
  return (
    <section className="benefits-section">
      <div className="container">
        <h2 className="headline">IN THIS 3 DAY CHALLENGE, YOU WILL LEARN</h2>

        <div className="row benefits">
          <div className="col-md-4">
            <div className="benefit">
              <img width="100" src={benefit1Image} />
              <h3>How to come up with a beautiful design for your website</h3>
              <p>
                You'll learn exactly how to design your website in line with the
                latest UX and UI trends that users will love!
              </p>
            </div>
          </div>

          <div className="col-md-4">
            <div className="benefit">
              <img width="100" src={benefit2Image} />
              <h3>How to build your website without tech people or $20k</h3>
              <p>
                I'm going to show you how you can build a website with new
                technology that makes it as easy as dragging and dropping!
              </p>
            </div>
          </div>

          <div className="col-md-4">
            <div className="benefit">
              <img width="100" src={benefit3Image} />
              <h3>
                How to embed contact forms, photos, videos, email and a blog
              </h3>
              <p>
                I'll show you how to make your website "fancy" so it looks great
                and has all the features you want in it!
              </p>
            </div>
          </div>
        </div>

        <div className="cta-container">
          <Link to="#form" className="cta w-full">
            Yes, sign me up for the No Code Website Building Challenge
          </Link>
        </div>
      </div>
    </section>
  )
}

export default ChallengeBenefits
